import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Typography } from '@mui/material';

// Composant qui permet de choisir un utilisateur
function Utilisateur(props){
    const { utilisateur, setUtilisateur, dataUtilisateur, setIdOlaf, setIdUser } = props;

    // Si l'utilisateur est vide, on met 'Utilisateur'
    if (utilisateur === '' || utilisateur === ' ' || utilisateur === null || utilisateur === undefined) {
        setUtilisateur('Utilisateur');
    }

    // Fonction qui permet de changer l'utilisateur
    const handleChange = (event, newValue) => {
        const selectedOption = dataUtilisateur.find(option => option.nom_pre === newValue?.nom_pre);
        if (selectedOption) {
            setUtilisateur(newValue.nom_pre);
            setIdOlaf(newValue.id_olaf);
            setIdUser(newValue.id);
        } else {
            setUtilisateur('Utilisateur');
            setIdOlaf(0);
            setIdUser(0);
        }
    }

    return(
        <div className='Utilisateur'>
            <Typography variant="h6" component="div" gutterBottom>
                Choisir un utilisateur : 
            </Typography>
            <Autocomplete
                disablePortal
                id="Nom Prenom"
                options={dataUtilisateur}
                getOptionLabel={(option) => option.nom_pre}
                sx={{ width: 300 }}
                onChange={handleChange}
                renderInput={(params) => <TextField {...params} label="Nom Prenom" />}
            />
        </div>
    )
}

export default Utilisateur;