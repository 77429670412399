import React, { useState } from 'react';
import { DateTime } from 'luxon';
import Formulaire from './Formulaire_date';
import Nbr from './nbr_rpo.js';
import './Admin.css';
import Rpo from './Div_rpo.js';

function Main(props) {
    const {setConnected} = props;
    const [dateDebut, setDateDebut] = useState(DateTime.local());
    const [dateFin, setDateFin] = useState(DateTime.local());
    const [creation, setCreation] = useState(DateTime.local());
    const [limites, setLimites] = useState(DateTime.local());

    return(
        <div className='Main_admin'>
            <Formulaire dateDebut={dateDebut} setDateDebut={setDateDebut} dateFin={dateFin} setDateFin={setDateFin} 
            creation={creation} setCreation={setCreation} limites={limites} setLimites={setLimites} />
            <div className='ligne'>
                <Nbr setConnected={setConnected}/>
                <Rpo dateDebut={dateDebut} dateFin={dateFin} creation={creation} limites={limites}/>
            </div>
        </div>
    )   
}

export default Main;
