import React, { useEffect, useState } from "react";
import { Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";

// Fonction pour afficher la liste des RPO et les supprimer si besoin
function Main() {
    const [utilisateur, setUtilisateur] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [listRpoModalOpen, setListRpoModalOpen] = useState(false);

    // Fonction pour récupérer les données
    useEffect(() => {
        fetchUtilisateur();
    }, []);
    
    const fetchUtilisateur = async () => {
        try {
            let url = "/php/user.php";
            if (window.location.hostname === "localhost" || window.location.port === "3000") {
                url = 'http://localhost/oui/user.php';
            }
            const response = await fetch(url);
            const utilisateur = await response.json();
            setUtilisateur(utilisateur);
        } catch (error) {
            console.error('Erreur lors de la requête :', error);
        }
    };

    // Fonction pour supprimer un utilisateur
    const handleSupprimer = (index) => {
        setSelectedIndex(index);
        setDeleteConfirmationOpen(true);
    }

    // Confirmation de la suppression
    const handleSupprimerConfirm = async () => {
        console.log(selectedIndex);
        const envoie = {
            id: utilisateur[selectedIndex]['id'],
            nom_pre: utilisateur[selectedIndex]['nom_pre'],
        };
        try {
            let url = "/php/Suppr_rpo.php";
            if (window.location.hostname === "localhost" && window.location.port === "3000") {
                url = 'http://localhost/oui/Suppr_rpo.php';
            }
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(envoie)
            };
            const response = await fetch(url, requestOptions);
            const data = await response.json();
            console.log(data);
            setDeleteConfirmationOpen(false);

            fetchUtilisateur();
        } catch (error) {
            console.error('Erreur lors de la requête :', error);
        }
    }

    // Annulation de la suppression
    const handleSupprimerCancel = () => {
        setDeleteConfirmationOpen(false);
    }

    // Fonction pour ouvrir la liste des RPO
    const handleListRpoClick = () => {
        fetchUtilisateur();
        setListRpoModalOpen(true);
    }

    return (
        <div className="Liste_rpo">
            <Button variant="contained" onClick={handleListRpoClick}>Liste rpo</Button>

            <Dialog open={listRpoModalOpen} onClose={() => setListRpoModalOpen(false)}>
                <DialogTitle >Liste RPO</DialogTitle>
                <DialogContent>
                    {utilisateur.map((user, index) => (
                        <div key={index} className='Suppr_user'>
                            <div className="user_text">
                                <Typography variant="body1">
                                    {user.nom_pre}
                                </Typography>
                                <Button variant="contained" onClick={() => handleSupprimer(index)}>
                                    Supprimer
                                </Button>
                            </div>
                        </div>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setListRpoModalOpen(false)}>Fermer</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={deleteConfirmationOpen} onClose={handleSupprimerCancel}>
                <DialogTitle>Confirmation de suppression</DialogTitle>
                <DialogContent>
                    <Typography>Êtes-vous sûr de vouloir supprimer cette personne ?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSupprimerCancel}>Annuler</Button>
                    <Button onClick={handleSupprimerConfirm}>Confirmer</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default Main;
