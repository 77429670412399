import { Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import './Connexion.css';

//Page de connexion permet de verifier si l'utilisateur est un admin ou un utilisateur normal
function Main(props) {
    const { setConnected } = props;
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");

    //permet de recuperer le login de l'utilisateur
    const handleLoginChange = (event) => {
        setLogin(event.target.value);
    }

    //permet de recuperer le mot de passe de l'utilisateur
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }

    //renvoie le login et le mot de passe a la page php pour verifier si il a tapé des identifiant existant et voir si 
    //l'utilisateur est un admin ou un utilisateur normal 
    const handleSubmit = async (event) => {
        event.preventDefault();
        const envoie = {
            login: login,
            mdp: password
        };
        try {
            let url = "/php/connexion.php";
            if (window.location.hostname === "localhost" && window.location.port === "3000") {
                url = 'http://localhost/oui/connexion.php';
            }
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(envoie)
            };
            const response = await fetch(url, requestOptions);
            const data = await response.json();
            setConnected(data.rep);
        } catch (error) {
            console.error('Erreur lors de la requête :', error);
        }
    };
    
    //permet de valider le formulaire avec la touche entrer
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSubmit(event);
        };
    };

    //permet de vider les champs login et password
    const handleBreak = (event) => {
        setLogin("");
        setPassword("");
    }

    return (
        <div className='Connexion'>
            <Grid className='Dispo'>
                <Typography variant="h4" component="div" gutterBottom>
                    Connexion
                </Typography>
                <Grid item>
                    <TextField
                        id="Login"
                        label="Login"
                        onChange={handleLoginChange}
                        sx={{backgroundColor:'white'}}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        type='password'
                        id="Password"
                        label="Password"
                        onChange={handlePasswordChange}
                        onKeyPress={handleKeyPress}
                        sx={{backgroundColor:'white',marginTop:'15px',marginBottom:'15px'}}
                    />
                </Grid>
                <Grid item>
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" onClick={handleBreak} startIcon={<DeleteIcon />} sx={{backgroundColor:'white'}}>
                            Effacer
                        </Button>
                        <Button onClick={handleSubmit} variant="contained" endIcon={<SendIcon />}>
                            Envoyer
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </div>
    )
}

export default Main;
