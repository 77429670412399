import React,{useEffect,useState} from "react";
import Person1 from './Personne1.js';
import Person2 from './Personne2.js';
import './Echange.css';
import { Button } from "@mui/material";

function Main(props){
    const {setConnected} = props;
    const [personne1, setPersonne1] = useState("");
    const [personne2, setPersonne2] = useState("");
    const [typeEchange, setTypeEchange] = useState(0);
    const [dataPersonne, setDataPersonne] = useState([]);
    const [choix1, setChoix1] = useState([]);
    const [choix2, setChoix2] = useState([]);

    // Fonction pour récupérer les données
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                let url = "/php/user.php";
                if (window.location.hostname === "localhost" || window.location.port === "3000") {
                    url = 'http://localhost/oui/user.php';
                }
                const response = await fetch(url);
                const jsonData = await response.json();
                setDataPersonne(jsonData);
            } catch (error) {
                console.error('Erreur lors de la récupération des données de la bdd:', error);
            }
        };
        fetchUserData();
    },[]);
    
    // Fonction pour valider les données
    const handleValidate = () => {
        if(personne1 === "" || personne2 === ""){
            alert('Veuillez remplir les deux champs Utilisateurs');
            return;
        }
        if(personne1 === personne2){
            alert('Vous ne pouvez pas échanger avec la même personne');
            return;
        }
        if(choix1.length === 0){
            alert('Veuillez choisir les jours d\'échange de la première personne');
            return;
        }
        if(typeEchange === 1 && choix2.length === 0){
            alert('Veuillez choisir les jours d\'échange de la deuxième personne étant donné que vous avez choisi un échange');
            return;
        }
        const envoie = {
            personne1: personne1,
            personne2: personne2,
            choix1: choix1,
            choix2: choix2,
            typeEchange: typeEchange
        }

        try{
            let url = "/php/Echange.php";
            if (window.location.hostname === "localhost" || window.location.port === "3000") {
                url = 'http://localhost/oui/Echange.php';
            }
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(envoie)
            };
            fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => alert(data));    
        }
        catch(error){
            console.error('Erreur lors de la requête :', error);
        }
    }

    return(
        <>
            <Button variant='contained' id="glob" onClick={()=>setConnected(2)}>Retour</Button>
            <div className='main_echange'>
                <Person1 personne2={personne2} personne1={personne1} setPersonne1={setPersonne1} dataPersonne={dataPersonne} setChoix={setChoix1}/>
                <Person2 personne1={personne1} personne2={personne2} setPersonne2={setPersonne2} dataPersonne={dataPersonne} typeEchange={typeEchange} setTypeEchange={setTypeEchange} setChoix={setChoix2}/>
            </div>
            <Button variant='contained' onClick={handleValidate}>Valider</Button>
        </>
    );
}

export default Main;