import React, { useEffect,useState } from "react";
import { Autocomplete, Checkbox } from "@mui/material";
import TextField from '@mui/material/TextField';
import { Check } from "@mui/icons-material";
import { Chip } from "@mui/material";

function Main(props){
    const {personne1, personne2, setPersonne2, dataPersonne, typeEchange, setTypeEchange, setChoix} = props;
    const [data, setData] = useState([]);
    const [tab,setTab] = useState('');

    // Fonction pour gérer le choix de la personne
    const handlePersonne2 = (event, newValue) => {
        if (newValue === null) {
            setPersonne2('');
            setData([]);
            setTab('');
            return;
        }
        if(newValue.nom_pre === personne1){
            alert('Vous ne pouvez pas échanger avec la même personne');
            return;
        }
        console.log(newValue);
        const selectedOption = dataPersonne.find(option => option.nom_pre === newValue?.nom_pre);
        if (selectedOption) {
            setPersonne2(newValue.nom_pre);
        } else {
            setPersonne2('');
            setData([]);
            setTab('');
        }
    };

    // Fonction pour récupérer les données
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                let url = "/php/Verif_semaine.php";
                if (window.location.hostname === "localhost" || window.location.port === "3000") {
                    url = 'http://localhost/oui/Verif_semaine.php';
                }
                const envoie = {
                    personne: personne2,
                }
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(envoie),
                });
                const jsonData = await response.json();
                setData(jsonData);
            } catch (error) {
                console.error('Erreur lors de la récupération des données de la bdd:', error);
            }
        };
        if (personne2 !== '' && personne2 !== '<empty string>' && personne2 !== "") {
            fetchUserData();
        }
    }, [personne2]);

    // Fonction pour gérer le choix de la date
    const handleChoix = (event, index) => {
        setChoix(data[index]);
        setTab(index);
    } 

    // Fonction pour gérer le type d'échange
    const handleCheck = (event) => {
        if(event.target.checked){
            setTypeEchange(1);
        }else{
            setTypeEchange(0);
            setChoix([]);
            setTab('');
        }
    }

    return(
        <div className='Personne1'>
            <Autocomplete
                disablePortal
                id="Nom Prenom"
                options={dataPersonne}
                getOptionLabel={(option) => option.nom_pre}
                sx={{ width: 300 }}
                onChange={handlePersonne2}
                renderInput={(params) => <TextField {...params} label="Nom Prenom" />}
            />
            <div className='space'>
                <label className='echange' htmlFor="echange-checkbox" style={{ cursor: 'pointer' }}>Échange</label>
                <Checkbox
                    id="echange-checkbox"
                    className='echange'
                    onClick={handleCheck}
                    value={typeEchange}
                    checkedIcon={<Check />}
                />
            </div>
            {typeEchange === 1 && data.length > 0 && ( // Condition pour afficher les chips seulement si typeEchange est égal à 1
                <div>
                    {data.map((value, index) => (
                        <Chip
                            key={index}
                            onClick={(event) => handleChoix(event, index)}
                            label={`Date: ${value.debut} - ${value.fin}, Semaine: ${value.semaine}`}
                            sx={{ margin: "4px" ,backgroundColor: tab === index ? "green" : "#1976D2", color: "white"}}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}

export default Main;