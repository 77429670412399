import { Typography, Button, Modal, Box } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Calendar from '../All/Calendrier.js';
// import { set } from 'date-fns';

function Main(props) {
    const { setConnected } = props;
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    const [openModal, setOpenModal] = useState(false);

    // Fonction pour récupérer les données
    useEffect(() => {
        const fetchData = () => {
            let url = "/php/nbr_rpo.php";
            let url2 = "/php/nombre_valide_rpo.php";
            if (window.location.hostname === "localhost" || window.location.port === "3000") {
                url = 'http://localhost/oui/nbr_rpo.php';
                url2 = 'http://localhost/oui/nombre_valide_rpo.php';
            }
            fetch(url)
                .then(res => res.json())
                .then(data => setData(data));
    
            fetch(url2)
                .then(res => res.json())
                .then(data2 => setData2(data2));
        };
    
        fetchData();
    
        const interval = setInterval(fetchData, 5 * 60 * 1000); // 5 minutes * 60 secondes * 1000 millisecondes
        return () => clearInterval(interval);
    }, []);

    // Fonction pour valider les RPO
    const handleValidate = async () => {
        let url = "/php/Algo2.php";
        if (window.location.hostname === "localhost" || window.location.port === "3000") {
            url = 'http://localhost/oui/Algo2.php';
        }
        await fetch(url)
            .then(res => res.json())
            .then(data => alert(data));
    };

    // Fonction pour gérer le suivi des RPO
    const handleSuivi = async () => {
        setConnected(3);
    };
    
    // Fonction pour gérer l'échange
    const handleEchange = async () => {
        setConnected(4);
    };

    // Fonction pour ouvrir le planning
    const handleOpenModal = () => {
        setOpenModal(true);
    };

    // Fonction pour fermer le planning
    const handleCloseModal = () => {
        setOpenModal(false);
    };

    return (
        <div className='nbr_rpo'>
            <Typography variant="h6" component="div">
                Nombre de RPO : {data}
            </Typography>
            <Typography variant="h6" component="div">
                Nombre de RPO validés : {data2}
            </Typography>
            <div className='bouton_rpo'>
                <Button variant="contained" onClick={handleValidate} style={{ marginTop: '20px', width: 'fit-content', margin: 'auto', display: 'block' }}>Choix RPO</Button>
                <Button variant='contained' onClick={handleSuivi} style={{ marginTop: '20px', width: 'fit-content', margin: 'auto', display: 'block' }}>Suivi RPO</Button>
                <Button variant="contained" onClick={handleOpenModal} style={{ marginTop: '20px', width: 'fit-content', margin: 'auto', display: 'block' }}>Planning RPO</Button>
                <Button variant="contained" onClick={handleEchange} style={{ marginTop: '20px', width: 'fit-content', margin: 'auto', display: 'block' }}>Échange</Button>
            </div>
            <Modal open={openModal} onClose={handleCloseModal}>
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                    <Calendar />
                </Box>
            </Modal>
        </div>
    )
}

export default Main;
