import { Button, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTime } from 'luxon';

// import './Formulaire.css';


// partie qui permet de créer les périodes de RPO et la limite de choix
function Main(props){
    const {dateDebut, setDateDebut, dateFin, setDateFin, creation, setCreation, limites, setLimites} = props;

    // Fonction pour gérer la date de début
    const handleDateDebut = (newValue) => {
        setDateDebut(newValue);
        if (newValue > dateFin) setDateFin(newValue);
    };

    // Fonction pour gérer la date de fin
    const handleDateFin = (newValue) => {
        if (newValue < dateDebut)alert('La date de fin ne peut pas être inférieure à la date de début');
        else setDateFin(newValue);
    };

    // Fonction pour gérer la date limite
    const handleLimites = (newValue) => {
        if(newValue < dateDebut) setLimites(newValue);
        else alert('La date limite doit être inférieure à la date de début');
    };

    // Fonction pour valider les dates
    const handleValidate = async (event) => {
        setCreation(DateTime.local());

        const envoie = {
            dateDebut: dateDebut.toISODate(),
            dateFin: dateFin.toISODate(),
            creation: creation.toISODate(),
            limite: limites.toISODate()
        };

        console.log(envoie);

        try {
            let url = '/php/creaPeriodes.php';//a changer une fois en ligne
            if (window.location.hostname === 'localhost' || window.location.port === '3000') {
                url = 'http://localhost/oui/creaPeriodes.php';
            }
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(envoie)
            };
            const response = await fetch(url, requestOptions);
            const data = await response.json();
            console.log(data);
            alert(data.rep);
        } catch (error) {
            console.error('Erreur lors de la requête :', error);
        }
    };

    return (
        <div>
            <Typography variant='h1' component='div'>
                Admin
            </Typography>
            <div className='First'>
                <div className='Second'>
                    <Typography variant='h6' component='div' sx={{ textAlign: 'left' }}>
                        Période de RPO :
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <div className='Date'>
                            <DatePicker
                                label='Date de début'
                                value={dateDebut}
                                onChange={handleDateDebut}
                                renderInput={(params) => <TextField {...params} />}
                                format='dd/MM/yyyy'
                            />
                            <Typography variant='h6' component='div' sx={{ marginLeft: '10px', marginRight: '10px'}}>
                                _
                            </Typography>
                            <DatePicker
                                label='Date de fin'
                                value={dateFin}
                                onChange={handleDateFin}
                                renderInput={(params) => <TextField {...params} />}
                                format='dd/MM/yyyy'
                            />
                        </div>
                    </LocalizationProvider>
                </div>
                <div className='third'>
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <Typography variant='h6' component='div' sx={{ textAlign: 'left'}}>
                            Date limite de validation : 
                        </Typography>
                        <DatePicker
                            label='Date limite'
                            value={limites}
                            onChange={handleLimites}
                            renderInput={(params) => <TextField {...params} />}
                            format='dd/MM/yyyy'
                            sx={{ marginLeft: '10px' }}
                        />
                    </LocalizationProvider>
                </div>
                <Button variant='contained' sx={{ marginBottom: '10px', margin: 'auto', display: 'block', width: 'fit-content' }} onClick={handleValidate}>Valider</Button>
            </div>
        </div>
    );
}

export default Main;