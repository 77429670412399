import React, { useEffect, useState }  from 'react';
import Utilisateur from './Utilisateur';
import Menu from './Menu';
import './User.css';
import { Button, Modal, Box } from '@mui/material';
import Calendar from '../All/Calendrier.js';

// Menu principal de la partie utilisateur
function Main(){
    const [dataUtilisateur, setDataUtilisateur] = useState([]);
    const [idUser, setIdUser] = useState(0);
    const [idOlaf, setIdOlaf] = useState(0);
    const [utilisateur, setUtilisateur] = useState("Utilisateur");
    const [data, setData] = useState([]);
    const [dateSemaine, setDateSemaine] = useState(0);
    const [limite, setLimite] = useState(0);
    const [openModal, setOpenModal] = useState(false);

    // Récupération des données de la bdd
    useEffect(() => {
        // Récupération des données de l'utilisateur
        const fetchUserData = async () => {
            try {
                let url = "/php/user.php";
                if (window.location.hostname === "localhost" || window.location.port === "3000") {
                    url = 'http://localhost/oui/user.php';
                }
                const response = await fetch(url);
                const jsonData = await response.json();
                setDataUtilisateur(jsonData);
            } catch (error) {
                console.error('Erreur lors de la récupération des données de la bdd:', error);
            }
        };

        // Récupération des données des dates
        const fetchDateData  = async () => {
            try{
                let url = "/php/date_rpo.php";
                if (window.location.hostname === "localhost" || window.location.port === "3000") {
                    url = 'http://localhost/oui/date_rpo.php';
                }
                const response = await fetch(url);
                const jsonData = await response.json();
                setData(jsonData);
                setDateSemaine(new Array(jsonData.length).fill(0));

                let url2 = "/php/limite.php";
                if (window.location.hostname === "localhost" || window.location.port === "3000") {
                    url2 = 'http://localhost/oui/limite.php';
                }
                const response2 = await fetch(url2);
                const jsonData2 = await response2.json();
                const dateParts = jsonData2.split('-');
                const formattedDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
                setLimite(formattedDate);
            }catch(error){
                console.error('Erreur lors de la récupération des données de la bdd:', error);
            }
        };
    
        fetchUserData();
        fetchDateData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // Fonction pour ouvrir et fermer la modal
    const handleOpenModal = () => {
        setOpenModal(true);
    };

    // Fonction pour fermer la modal
    const handleCloseModal = () => {
        setOpenModal(false);
    };
    
    // Fonction pour valider le planning RPO
    const handleValidate = () => {
        let compteur = 0;
        for(let i = 0; i < dateSemaine.length; i++){
            if(dateSemaine[i] != 0){
                compteur++;
            }
        }
        if(utilisateur === 'Utilisateur'){
            alert('Veuillez choisir un utilisateur');
            return;
        }else if (compteur <= 1 ) {
            alert('Veuillez choisir au moins deux semaine');
            return;
        }
        else{
            const dataToSend = {
                nom_pre : utilisateur,
                semaine : data,
                choix : dateSemaine,
                id_olaf : idOlaf,
                id_user : idUser
            };
            let url3 = "/php/envoie_rpo.php"
            if(window.location.hostname === "localhost" || window.location.port === "3000"){
                url3 = 'http://localhost/oui/envoie_rpo.php';
            }
            fetch(url3, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataToSend),
            })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                alert('Planning RPO envoyé');
            })
        }
    }
    
    return (
        <div className='User'>
            {limite !== 0 && (
                <p>La date limite est le {limite}</p>
            )}
            
            {(utilisateur === 'Utilisateur' || utilisateur === 'utilisateur') ? (
                <Utilisateur utilisateur={utilisateur} setUtilisateur={setUtilisateur} dataUtilisateur={dataUtilisateur} 
                    setIdOlaf={setIdOlaf} setIdUser={setIdUser}/>
            ) : (
                <div className='Global_menu'>
                    <Menu utilisateur={utilisateur} data={data} dateSemaine={dateSemaine} setDateSemaine={setDateSemaine}/>
                    <div className='bouton_rpo'>
                        <Button variant="contained" onClick={handleOpenModal} style={{ marginTop: '20px', width: 'fit-content', margin: 'auto', display: 'block' }}>
                            Planning RPO
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleValidate} sx={{marginTop: '20px', width: 'fit-content', margin: 'auto', display: 'block'}}>
                            Valider
                        </Button>
                    </div>
                    <Modal open={openModal} onClose={handleCloseModal}>
                        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                            <Calendar />
                        </Box>
                    </Modal>
                </div>
        )}
        </div>
    )
}

export default Main;