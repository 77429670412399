import React, { useEffect, useState } from "react";
import { Typography, Button, Grid, Select, MenuItem, Chip } from "@mui/material";

function Main() {
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);
  const [data, setData] = useState([]);

  function filterDataByYear(data, year) {
    return data.filter(item => {
      const annee = parseInt(item.annee);
      return annee === year;
    });
  }

  useEffect(() => {
    const fetchData = async () => {
      let url = "/php/Dernier_vac.php";
      if(window.location.hostname === "localhost" || window.location.port === "3000") {
        url = 'http://localhost/oui/Dernier_vac.php';
      }
      const response = await fetch(url);
      const data = await response.json();
      const newdata = filterDataByYear(data, year);
      setData(newdata);
    };
    fetchData();
    const interval = setInterval(fetchData, 5 * 60 * 1000); // 5 minutes * 60 secondes * 1000 millisecondes
    return () => clearInterval(interval);
  }, [year]);

  const daysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const groupNamesByWeek = (data, numero_semaine) => {
    let names = data.filter(item => parseInt(item.semaine) === numero_semaine).map(item => item.nom);
    return names.join(', ');
  };

  // Fonction pour obtenir le premier jour de la semaine
  function getFirstDayOfWeek(weekNumber, year) {
    const januaryFirst = new Date(year, 0, 1);
    const daysToMonday = (1 - januaryFirst.getDay() + 7) % 7;
    const firstMonday = new Date(year, 0, 1 + daysToMonday);
    const firstDayOfWeek = new Date(firstMonday);
    firstDayOfWeek.setDate(firstMonday.getDate() + (weekNumber - 1) * 7);
    return firstDayOfWeek;
  }

  // Fonction pour obtenir le dernier jour de la semaine
  function getLastDayOfWeek(weekNumber, year) {
    const firstDayOfWeek = getFirstDayOfWeek(weekNumber, year);
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6); // Ajoute 6 jours pour obtenir le dernier jour
    return lastDayOfWeek;
  }

  const firstDayOfMonth = (month, year) => {
    return new Date(year, month, 1).getDay();
  };

  const getWeekNumber = (date) => {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  };

  function WeekOneNumbers(year) {
    const weekOneNumbers = [];
  
    for (let month = 0; month < 12; month++) {
      const firstDayOfMonth = new Date(year, month, 1);
      const weekNumber = getISOWeek(firstDayOfMonth);
      weekOneNumbers.push(weekNumber);
    }
  
    return weekOneNumbers;
  }
  
  function getISOWeek(date) {
    const onejan = new Date(date.getFullYear(), 0, 1);
    const weekNumber = Math.ceil((((date - onejan) / 86400000) + onejan.getDay() + 1) / 7);
    return weekNumber;
  }

  const renderCalendar = () => {
    const monthDays = daysInMonth(month, year);
    const firstDay = firstDayOfMonth(month, year);
    const firstDayOffset = firstDay === 0 ? 6 : firstDay - 1;
  
    let calendar = [];
    let semaine = [];
    let weekOneNumbers = WeekOneNumbers(year);
    let day = 1;
  
    // Créer une ligne de noms de jours de la semaine
    const daysOfWeek = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
    const daysRow = daysOfWeek.map((day, index) => (
      <Grid item xs key={index} style={{ textAlign: 'center' }}>
        <Typography variant="h6">{day}</Typography>
      </Grid>
    ));
  
    calendar.push(
      <Grid container spacing={0} key={`week-days`} justifyContent="space-between">
        {daysRow}
      </Grid>
    );
  
    // Structure de données pour stocker les noms par semaine
    let namesByWeek = {};
  
    // Parcourir les jours du mois
    for (let i = 0; i < monthDays; i++) {
      const currentDate = new Date(year, month, day);
      const weekNumber = getWeekNumber(currentDate);
      const names = groupNamesByWeek(data, weekNumber);
  
      // Vérifier si des noms existent pour cette semaine et les ajouter à la structure de données
      if (names) {
        if (!namesByWeek[weekNumber]) {
          namesByWeek[weekNumber] = new Set();
        }
        names.split(',').forEach(name => namesByWeek[weekNumber].add(name.trim()));
      }
  
      // Incrémenter le jour
      day++;
    }
  
    // Générer les puces correspondantes à chaque semaine
    for (const weekNumber in namesByWeek) {
      const names = Array.from(namesByWeek[weekNumber]);
      let shift = 0;
      let endingDay = 6;
      let chipWidth = '';
      let chips = '';
  
      if (weekNumber === weekOneNumbers[month].toString()) {
        // Si la semaine actuelle est la première semaine, ajustez le décalage pour qu'il commence au jour réel du mois
        shift = firstDayOffset;
        endingDay = firstDayOffset + (7 - firstDayOffset - 1);
        chipWidth = `${(100 / 7) * (7 - firstDayOffset)}%`;
        chips = (
          <Grid item xs key={`week-${weekNumber}-chips`} container style={{ width: '100%', justifyContent: 'right' }}>
            {names.map((name, index) => (
              <Chip key={index} label={name} color="primary" style={{ width: chipWidth, marginBottom: '5px' }} />
            ))}
          </Grid>
        );
      } else {
        if (weekNumber === getWeekNumber(new Date(year, month, monthDays)).toString()) {
          // Pour la dernière semaine, ajustez la fin en fonction du dernier jour du mois
          endingDay = firstDayOffset + (monthDays % 7) - 1;
        }
        chipWidth = `${(100 / 7) * (endingDay - shift + 1)}%`;
        chips = (
          <Grid item xs key={`week-${weekNumber}-chips`} container style={{ width: '100%' }}>
            {names.map((name, index) => (
              <Chip key={index} label={name} color="primary" style={{ width: chipWidth, marginBottom: '5px' }} />
            ))}
          </Grid>
        );
      }
  
      let first = getFirstDayOfWeek(weekNumber, year);
      let last = getLastDayOfWeek(weekNumber, year);
  
      const weekGrid = (
        <Grid container spacing={0} key={`week-${weekNumber}`} style={{ minHeight: '50px' }}>
          {chips}
        </Grid>
      );
  
      semaine.push(
        <Grid item xs key={`week-${weekNumber}`} container style={{ minHeight: '50px' }}>
          <Typography variant="body2" style={{ fontWeight: 'bold' }}>S{weekNumber} ({first.toLocaleDateString()} - {last.toLocaleDateString()}) :</Typography>
        </Grid>
      );
  
      calendar.push(weekGrid);

      // Si le dernier jour de la semaine est le 30 ou 31, on arrête pour eviter d'avoir une semaine en plus potentiellement
      // faire en sorte de regarder si on est bien au bon mois aussi mais normalement ça devrait etre bon de ce côté.
      let regex = /^(30|31)/;
      if(regex.test(last.toLocaleDateString())){
        break;
      }
    }
    let tab = [];
    // On ajoute les semaines et les calendriers
    tab.push(
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px', width: "100%" }}>
        <div style={{ width: "30%",marginTop:'35px' }}>
          {semaine}
        </div>
        <div style={{ width: "90%" }}>
          {calendar}
        </div>
      </div>
    );
    return tab;
  };
  
  // Fonction pour changer le mois
  const changeMonth = (event) => {
    setMonth(event.target.value);
  };

  // Fonction pour changer l'année
  const changeYear = (delta) => {
    setYear(year + delta);
  };

  return (
    <div style={{ margin: "20px", width: "1000px", height:'400px'}}>
      <Typography variant="h5" align="center" gutterBottom>
        {new Date(year, month).toLocaleDateString("fr-FR", {
          month: "long",
          year: "numeric",
        })}
      </Typography>
      <Grid container justifyContent="center" alignItems="center" spacing={1}>
        <Grid item>
          <Button variant="outlined" onClick={() => changeYear(-1)}>
            Année précédente
          </Button>
        </Grid>
        <Grid item>
          <Select value={month} onChange={changeMonth}>
            {Array.from({ length: 12 }, (_, i) => (
              <MenuItem key={i} value={i}>
                {new Date(0, i).toLocaleString("default", { month: "long" })}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={() => changeYear(1)}>
            Année suivante
          </Button>
        </Grid>
      </Grid>
      <div>
        {renderCalendar()}
      </div>
    </div>
  );
}

export default Main;