import React, { useEffect, useState } from 'react';
import Ligne from './Ligne.js';

// Menu principal de l'utilisateur qui contient les choix de l'utilisateur
function Main(props){
    const {data, utilisateur, dateSemaine, setDateSemaine} = props; //data = date de la periode //setDateSemaine = tableau de boolean si le mec est chaud ou pas
    const [test, setTest] = useState(0);

    //
    useEffect(() => {
        const fetchData = async () => {
            let url = 'php/periode_rpo_actuel.php'
            if (window.location.hostname === "localhost" || window.location.port === "3000") {
                url = 'http://localhost/oui/periode_rpo_actuel.php';
            }
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ nom_pre: utilisateur })
            };
            const response = await fetch(url, requestOptions);
            const data = await response.json();

            if (data.message && data.message === "Aucun résultat trouvé") {
                setTest(1);
                const choix = new Array(dateSemaine.length).fill(0);
                setDateSemaine(choix);
            } else {
                const choix = data.map(item => parseInt(item.choix, 10));
                setDateSemaine(choix);
                setTest(0);
            }

            // faire une condition else qui met a true ou false les checkbox de datesemaine vrai = privilegié faux = a eviter
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[utilisateur]);

    
    // variable qui contient les lignes de chaque utilisateur
    let affichage = [];

    // affiche le nom de l'utilisateur et son choix pour les dates des periodes
    if(test === 0){
        affichage.push(<Ligne utilisateur={utilisateur} key={0} date={data} dateSemaine={dateSemaine} setDateSemaine={setDateSemaine} />)
    }else{
        affichage.push(<Ligne utilisateur={utilisateur} key={0} date={data} dateSemaine={dateSemaine} setDateSemaine={setDateSemaine} />)
    }

    return(
        <div className='Menu'>
            {affichage}
        </div>
    )
}

export default Main;