import React, { useEffect,useState } from "react";
import { Autocomplete, Chip } from "@mui/material";
import TextField from '@mui/material/TextField';

function Main(props){
    const {personne2, personne1, setPersonne1, dataPersonne, setChoix} = props;
    const [data, setData] = useState([]);
    const [tab,setTab] = useState('');

    // Fonction pour récupérer les données
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                let url = "/php/Verif_semaine.php";
                if (window.location.hostname === "localhost" || window.location.port === "3000") {
                    url = 'http://localhost/oui/Verif_semaine.php';
                }
                const envoie = {
                    personne: personne1,
                }
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(envoie),
                });
                const jsonData = await response.json();
                setData(jsonData);
            } catch (error) {
                console.error('Erreur lors de la récupération des données de la bdd:', error);
            }
        };
        if (personne1 !== '' && personne1 !== '<empty string>' && personne1 !== "") {
            fetchUserData();
        }
    }, [personne1]);

    // Fonction pour gérer le choix de la personne
    const handlePersonne1 = (event, newValue) => {
        if (newValue === null) {
            setPersonne1('');
            setData([]);
            return;
        }
        if(newValue.nom_pre === personne2){
            alert('Vous ne pouvez pas échanger avec la même personne');
            return;
        }
        const selectedOption = dataPersonne.find(option => option.nom_pre === newValue?.nom_pre);
        if (selectedOption) {
            setPersonne1(newValue.nom_pre);
        } else {
            setPersonne1('');
        }
    };

    // Fonction pour gérer le choix de la date
    const handleChoix = (event, index) => {
        setChoix(data[index]);
        setTab(index);
    } 

    return(
        <div className='Personne1'>
            <Autocomplete
                disablePortal
                id="Nom Prenom"
                options={dataPersonne}
                getOptionLabel={(option) => option.nom_pre}
                sx={{ width: 300 }}
                onChange={handlePersonne1}
                renderInput={(params) => <TextField {...params} label="Nom Prenom" />}
            />
            {data.length > 0 && (
                <div>
                    {data.map((value, index) => (
                        <Chip
                            key={index}
                            onClick={(event) => handleChoix(event, index)}
                            label={`Date: ${value.debut} - ${value.fin}, Semaine: ${value.semaine}`}
                            sx={{ margin: "4px" ,backgroundColor: tab === index ? "green" : "#1976D2", color: "white"}}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}

export default Main;