import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
// import { Style } from '@mui/icons-material';

// partie qui permet de voir le suivi des RPO pour l'année en cours
function Main(props){
    const {setConnected} = props;
    const [data, setData] = useState([]);
    const year = new Date().getFullYear();

    // Fonction pour filtrer les données 
    const columns = [
        { field: 'semaine', headerName: 'Semaine', width: 150, editable: false,},
        { field: 'nom', headerName: 'Nom',type: 'number', width: 300, editable: false,},
        { field: 'compteur', headerName: 'Compteur', type: 'number', width: 100, editable: false,},
      ];
    
    // Fonction pour récupérer les données
    useEffect(() => {
        const fetchData = async () => {
            let url = "/php/suivi_rpo.php";
            if(window.location.hostname === "localhost" || window.location.port === "3000") {
                url = 'http://localhost/oui/suivi_rpo.php';
            }
            const response = await fetch(url);
            const data = await response.json();
            setData(data);
        };
        fetchData();
        const interval = setInterval(fetchData, 5 * 60 * 1000); // 5 minutes * 60 secondes * 1000 millisecondes
        return () => clearInterval(interval);
    }, []);
    
    return(
        <div className='Main_admin'>
            <Button variant='contained' onClick={() => setConnected(2)} style={{ width: 'fit-content', display: 'block', marginRight: 'auto',marginLeft:"200px",marginBottom:'auto', marginTop:'100px' }}>Retour</Button>
            <Typography variant="h3" component="div" style={{marginBottom:'100px'}}>
                Suivi des RPO pour l'année {year}
            </Typography>
            <Box sx={{ height: 600, width: '50%', marginBottom:'20px',}}>
                <DataGrid
                    rows={data}
                    columns={columns}
                    initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 50,
                        },
                    },
                    }}
                    pageSizeOptions={[5,10,20,50]}
                    disableRowSelectionOnClick
                />
            </Box>
        </div>
    )
}

export default Main;