import React from 'react';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

// Ligne de l'utilisateur qui contient les choix de l'utilisateur
function Main(props){
    const {date, dateSemaine,setDateSemaine,utilisateur} = props; //date = date de la periode //setDateSemaine = tableau de boolean si le mec est chaud ou pas

    let affichageLigne = [];

    // Fonction qui permet de changer la couleur des boutons
    const handleButtonClick = (index, value) => {
        const updatedDateSemaine = [...dateSemaine];
        updatedDateSemaine[index] = value;
        setDateSemaine(updatedDateSemaine);
    };

    // Si le tableau est vide, on affiche un message de chargement
    if (!date || !Array.isArray(date) || date.length === 0) {
        affichageLigne.push(<p>Chargement en cours .....</p>)
    } else {
        for (let i = 0; i < date.length; i++) {
            affichageLigne.push(
                <div key={i} className='Ligne'>
                    <div>
                        <Typography variant="body1">
                            {date[i].numeroSemaine} ({date[i].dateDebut} - {date[i].dateFin}) : 
                        </Typography>
                    </div>
                    <div>
                        <ButtonGroup>
                            <Button variant="contained" onClick={() => handleButtonClick(i, 0)} 
                                style={{color:'black',backgroundColor: dateSemaine[i]===0 ? 'red' : 'white'}}>
                                A éviter
                            </Button>
                            <Button variant="contained" onClick={() => handleButtonClick(i, 1)}
                                style={{color:'black', backgroundColor: dateSemaine[i]===1 ? 'grey' : 'white',}}>
                                Indifférent
                            </Button>
                            <Button variant="contained" onClick={() => handleButtonClick(i, 2)}
                                style={{color:'black', backgroundColor: dateSemaine[i]===2 ? 'green' : 'white',}}>
                                A privilegier
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>
            )
        }
    }

    return(
        <div className='Div_ligne'>
            <Typography variant="h4" component="div" gutterBottom>
                {utilisateur}
            </Typography>
            {affichageLigne}
        </div>
    )

}

export default Main;