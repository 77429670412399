import './App.css';
import React, {useState} from 'react';
import Connexion from './component/Connexion/connexion.js';
import Admin from './component/Admin/Admin.js';
import User from './component/User/User.js';
import Suivi from './component/Admin/Datagrid_RPO.js'
import Echange from './component/Admin/Echange/Echange.js';


function App() {
  const [connected, setConnected] = useState(0);
  console.log(connected);

  let componentToDisplay;

  if (connected === 0) {
    componentToDisplay = <Connexion setConnected={setConnected} />;
  } else if (connected === 1) {
    componentToDisplay = <User />;
  } else if (connected === 2) {
    componentToDisplay = <Admin setConnected={setConnected} />;
  }
  else if (connected === 3) {
    componentToDisplay = <Suivi setConnected={setConnected} />;
  }
  else if(connected === 4){
    console.log('test');
    componentToDisplay = <Echange setConnected={setConnected} />;
  }

  return (
    <div className="App">
      {componentToDisplay}
    </div>
  );
}

export default App;
