import React, { useState } from 'react';
import { Modal, Typography, TextField, Button, Box } from '@mui/material';

// partie qui permet d'ajouter un nouvel RPO
function Main() {
    const [open, setOpen] = useState(false);
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [idolaf, setIdolaf] = useState('');
    const [mail, setMail] = useState('');

    // Fonction pour ouvrir la modal
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // Fonctions pour gérer les champs de saisie
    const handleNom = (event) => setNom(event.target.value);
    const handlePrenom = (event) => setPrenom(event.target.value);
    const handleIdolaf = (event) => setIdolaf(event.target.value);
    const handleMail = (event) => setMail(event.target.value);

    // Fonction pour envoyer les données
    const handleSubmit = (event) => {
        event.preventDefault();
        const envoie = {
            nom_pre: nom + ' ' + prenom,
            id_olaf: idolaf,
            mail: mail
        };
        try {
            let url = "/php/Ajout_rpo.php";
            if (window.location.hostname === "localhost" && window.location.port === "3000") {
                url = 'http://localhost/oui/Ajout_rpo.php';
            }
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(envoie)
            };
            fetch(url, requestOptions)
                .then(response => response.json())
                .then(data => console.log(data));
        } catch (error) {
            console.error('Erreur lors de la requête :', error);
        }
        setNom('');
        setPrenom('');
        setIdolaf('');
        setMail('');
        handleClose();
    }

    return (
        <div className='Ajout'>
            <Button variant="contained" onClick={handleOpen}>Ajouter</Button>
            <Modal open={open} onClose={handleClose}>
                <Box className='Box'>
                    <Typography variant="h5" gutterBottom>Ajouter un nouvel utilisateur</Typography>
                    <TextField label="Nom" value={nom} onChange={handleNom} sx={{ mb: 2 }} />
                    <TextField label="Prénom" value={prenom} onChange={handlePrenom} sx={{ mb: 2 }} />
                    <TextField label="Identifiant" value={idolaf} onChange={handleIdolaf} sx={{ mb: 2 }} />
                    <TextField label="Mail" value={mail} onChange={handleMail} sx={{ mb: 2 }} />
                    <div style={{ display: 'flex' ,flexDirection: 'row'}}>
                        <Button type="submit" variant="contained" sx={{ mr: 2 }} onClick={handleSubmit}>Ajouter</Button>
                        <Button onClick={handleClose} variant="outlined">Annuler</Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default Main;
