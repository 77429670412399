import React from 'react';
import Rpo from './rpo.js';
import AjoutRpo from './Ajout_Rpo.js';
import { Typography } from '@mui/material';

function Main(){
    return(
        <div className='rpo_modif'>
            <Typography variant="h6" component="div">
                Liste des RPO & modif
            </Typography>
            <div className='Bouton_rpo'>
                <AjoutRpo/>
                <Rpo/>
            </div>
        </div>
    )
}

export default Main;